import React from 'react'
import '../styles/Arrow.scss';

const Arrow = ({scrolled, scrolledToBottom}) => {

    return (
        <div className={ `Arrow ${scrolled ? 'scrolled' : ''} ${scrolledToBottom ? 'hidden' : ''}` }>
            <img className="Arrow__main" src="/images/arrow-scroll.png" />
        </div>
    )
}

export default Arrow