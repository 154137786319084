import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { tns } from '/node_modules/tiny-slider/src/tiny-slider'
import CaseSlide from './CaseSlide'
import Scale from "./hoc/Scale"
import '../styles/CaseStudy.scss'

const txt = {
    pl: {
    },
    en: {
    },
}
const cases = [
    {
        company: "APART",
        product: "Apart",
        type: "Expand Premium",
        imgName: "apart_desktop.jpg",
        videoName: "apart-desktop.mp4",
    },
    {
        company: "Teva",
        product: "Calperos",
        type: "Expand Premium",
        imgName: "calperos_desktop.jpg",
        videoName: "calperos.mp4",
    },
    {
        company: "APART",
        product: "Apart",
        type: "Mobile Expand Premium",
        imgName: "apart_mobile.jpg",
        videoName: "apart-mobile.mp4",
    },
    {
        company: "Disney Networks",
        product: "Fox",
        type: "Mobile Expand Premium",
        imgName: "fox_mobile.jpg",
        videoName: "fox.mp4",
    },
    {
        company: "Froneri Polska",
        product: "Oreo",
        type: "Mobile Expand Premium",
        imgName: "oreo_mobile.jpg",
        videoName: "oreo.mp4",
    },
    {
        company: "P4",
        product: "Play",
        type: "Mobile Expand Premium",
        imgName: "play_mobile.jpg",
        videoName: "play.mp4",
    },
    {
        company: "Mars",
        product: "Snickers",
        type: "Mobile Expand Premium",
        imgName: "snickers_mobile.jpg",
        videoName: "snickers.mp4",
    },
    {
        company: "Tchibo",
        product: "Tchibo",
        type: "Mobile Expand Premium",
        imgName: "tchibo_mobile.jpg",
        videoName: "tchibo.mp4",
    },
    {
        company: "PEPSICO",
        product: "Cheetos",
        type: "Expand Premium",
        imgName: "cheetos_desktop.jpg",
        videoName: "cheetos-desktop.mp4",
    },
    {
        company: "HEINZ",
        product: "Heinz",
        type: "Expand Premium",
        imgName: "heinz_desktop.jpg",
        videoName: "heinz-desktop.mp4",
    },
    {
        company: "L'OREAL",
        product: "La Roche Posay",
        type: "Expand Premium",
        imgName: "laroche_desktop.jpg",
        videoName: "loreal-desktop.mp4",
    },
    {
        company: "BMW",
        product: "Mini",
        type: "Expand Premium",
        imgName: "mini_desktop.jpg",
        videoName: "mini-desktop.mp4",
    },
    {
        company: "Renault",
        product: "Renault",
        type: "Mobile Expand Premium",
        imgName: "renault_mobile.jpg",
        videoName: "renault.mp4",
    },
    {
        company: "SAMSUNG",
        product: "Samsung Galaxy",
        type: "Expand Premium",
        imgName: "samsung_desktop.jpg",
        videoName: "samsung-desktop.mp4",
    },
    {
        company: "T-MOBILE",
        product: "T-Mobile",
        type: "Expand Premium",
        imgName: "tmobile_desktop.jpg",
        videoName: "tmobile-desktop.mp4",
    },
    {
        company: "VOLKSWAGEN",
        product: "Golf",
        type: "Expand Premium",
        imgName: "golf_desktop.jpg",
        videoName: "vw-desktop.mp4",
    },
]

const CaseStudy = ({deviceType, language}) => {
    const [video, setVideo] = useState()
    const [sliderBuilt, setSliderBuilt] = useState(false)
    const slidesAmount = Math.ceil(cases.length / 2)
    let slider
    let slides = []

    if(slides.length === 0) {
        for(let i = 0; i < slidesAmount; i++) {
            let case1 = cases[i*2]
            let case2 = cases[i*2+1]
            let slide = <div key={ i }><CaseSlide language={ language } case1={ case1 } case2={ case2 } setVideo={ setVideo } deviceType={ deviceType } /></div>
            slides.push(slide)
        }
    }

    useEffect(() => {
        if(!slider && !sliderBuilt) {
            setSliderBuilt(true)
            slider = tns({
                container: '#CaseStudy-slides',
                controlsContainer: '.CaseStudy__controls',
                prevButton: '.CaseStudy__arrow--left',
                nextButton: '.CaseStudy__arrow--right',
                slideBy: 1,
                rewind: true,
                lazyload: true,
                gutter: 20, //gap between images
                responsive: {
                    1024: {
                        items: 3,
                        gutter: 20,
                        // slideBy: 3,
                    },
                    768: {
                        items: 2,
                        gutter: 30,
                    },
                    480: {
                        items: 1,
                    },
                }
            })
        }
        return
    },[])

    return (
        <section className="CaseStudy">
            <div id="CaseStudy" className="container">
                { video }
                <div className="CaseStudy__slider">
                    <Scale trigger="#CaseStudy">
                        <div className="CaseStudy__slider-container">
                            <div id="CaseStudy-slides" className="CaseStudy__slides">
                                { slides }
                            </div>
                        </div>
                        <div className="CaseStudy__controls">
                            <img className="CaseStudy__arrow CaseStudy__arrow--left" src="/images/arrow-dark-green.png" />
                            <img className="CaseStudy__arrow CaseStudy__arrow--hover CaseStudy__arrow--left arrow-hover" src="/images/arrow-dark-green-hover.png" />
                            <img className="CaseStudy__arrow CaseStudy__arrow--right" src="/images/arrow-dark-green.png" />
                            <img className="CaseStudy__arrow CaseStudy__arrow--hover CaseStudy__arrow--right arrow-hover" src="/images/arrow-dark-green-hover.png" />
                        </div>
                    </Scale>
                </div>
            </div>
        </section>
    )
}

CaseStudy.propTypes = {
    language: PropTypes.string.isRequired,
}

export default CaseStudy