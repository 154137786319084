import React from 'react'
import PropTypes from 'prop-types'
import { Tween } from 'react-gsap'

const Reveal = (props) => {
    return (
        <Tween from={{ y: '100px', opacity: 0 }} to={{
            y: '0',
            opacity: 1,
            scrollTrigger: {
                trigger: props.trigger,
                start: props.start || '-100px center',
                end: props.end || '400px center',
                scrub: 0.5,
                // markers: true,
            },
        }} >
            { props.children }
        </Tween>
    )
}

Reveal.propTypes = {
    trigger: PropTypes.string.isRequired,
}

export default Reveal
