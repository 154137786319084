import React, { useState } from "react";
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { Tween } from 'react-gsap'
import "../styles/Navbar.scss";

const txt = {
    pl: {
        nav: {
            left: [
                ["o nas", "AboutUs"],
                ["case study", "CaseStudy"],
                ["klienci", "Clients"],
            ],
            right: [
                ["inni o nas", "OthersAboutUs"],
                ["fundacja", "Foundation"],
                ["kontakt", "Contact"]
            ],
        }
    },
    en: {
        nav: {
            left: [
                ["about us", "AboutUs"],
                ["case study", "CaseStudy"],
                ["clients", "Clients"],
            ],
            right: [
                ["credentials", "OthersAboutUs"],
                ["foundation", "Foundation"],
                ["contact", "Contact"]
            ]
        }
    }
};

const Navbar = ({ language, languages, setLanguage, scrolled, setScrolled }) => {
    const [section, setSection] = useState('')
    const [isHamburgerMenuVisible, setIsHamburgerMenuVisible] = useState(false)
    const navigate = useNavigate()
    const handleSetLanguage = (lang) => {
        setLanguage(lang)
        navigate(`/${lang}`, { replace: true })
        return
    }
    const handleLinkClick = () => {
        setScrolled(true)
        setTimeout(() => setIsHamburgerMenuVisible(false),400)
    }

    return (
        <nav className={ `Navbar ${scrolled ? 'scrolled' : ''} active-${section}` }>
            <div className={`Navbar__hamburger ${isHamburgerMenuVisible ? 'active' : ''}`} onClick={ () => setIsHamburgerMenuVisible(!isHamburgerMenuVisible) }>
                <div className="Navbar__hamburger-line Navbar__hamburger-line--top"></div>
                <div className="Navbar__hamburger-line Navbar__hamburger-line--middle"></div>
                <div className="Navbar__hamburger-line Navbar__hamburger-line--bottom"></div>
            </div>
            <ul className={ `Navbar__list Navbar__list--left ${isHamburgerMenuVisible ? 'showItems' : ''}` }>
                {txt[language].nav.left.map(navItem =>
                    <Tween key={ navItem[0] } to={{
                        scrollTrigger: {
                            trigger: `.${navItem[1]}`,
                            start: 'top center',
                            end: '90% center',
                            // markers: true,
                            onToggle: () => setSection(navItem[1]),
                        },
                    }} >
                        <li className={ `Navbar__list-item Navbar__list-item--${navItem[1]}` }>
                            <HashLink to={ `/${language}#` + navItem[1] } onClick={ handleLinkClick }>
                                <span>{ navItem[0] }</span>
                            </HashLink>
                        </li>
                    </Tween>
                )}
            </ul>
            <img className="Navbar__logo Navbar__logo--desktop" src="/images/logo.png" />
            {/* <img className="Navbar__logo Navbar__logo--mobile" src="/images/logo-mobile.png" /> */}
            <ul className={ `Navbar__list Navbar__list--right ${isHamburgerMenuVisible ? 'showItems' : ''}` }>
                {txt[language].nav.right.map(navItem =>
                    <Tween key={ navItem[0] } to={{
                        scrollTrigger: {
                            trigger: `.${navItem[1]}`,
                            start: 'top center',
                            end: '90% center',
                            // markers: true,
                            onToggle: () => setSection(navItem[1]),
                        },
                    }} >
                        <li className={ `Navbar__list-item Navbar__list-item--${navItem[1]}` }>
                            <HashLink to={ `/${language}#` + navItem[1] } onClick={ handleLinkClick }>
                                <span>{ navItem[0] }</span>
                            </HashLink>
                        </li>
                    </Tween>
                )}
            </ul>
            <div className="Navbar__languages">
                { languages.map((languageEl, i) =>
                <span key={languageEl}>
                    <span 
                        className={ languageEl === language ? "Navbar__language active" : "Navbar__language" }
                        onClick={ () => handleSetLanguage(languageEl) }
                    >
                    {languageEl}
                    </span>
                    <span className="Navbar__language-separator">
                        { i !== languages.length - 1 ? " | " : "" }
                    </span>
                </span>
                ) }
            </div>
        </nav>
    );
};

Navbar.propTypes = {
    language: PropTypes.string.isRequired,
    languages: PropTypes.array.isRequired,
    setLanguage: PropTypes.func.isRequired,
};

export default Navbar;
