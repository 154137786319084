import React from 'react'
import PropTypes from 'prop-types'
// import { gsap } from 'gsap'
import { Tween } from 'react-gsap'
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

const Scale = (props) => {
    return (
        <Tween from={{ scale: 1.35 }} to={{
            scale: 1,
            scrollTrigger: {
            trigger: props.trigger,
            start: '-100px center',
            end: 'center center',
            scrub: 0.5,
            // markers: true,
            },
        }} >
            { props.children }
        </Tween>
    )
}

Scale.propTypes = {
    trigger: PropTypes.string.isRequired,
}

export default Scale
