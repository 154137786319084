import React, { useEffect } from 'react'
import Button from './Button'
import LazyLoad from 'react-lazyload'

const txt = {
    pl: {
        button: 'zobacz case study',
        buttonBack: 'wróć',
    },
    en: {
        button: 'show case study',
        buttonBack: 'back',
    },
}

const CaseSlide = ({ case1, case2, deviceType, language, setVideo }) => {
    const handleShowVideo = (videoName, imgName) => {
        const video = <div className={ `CaseStudy__video-wrapper` }>
            <video className='CaseStudy__video' src={ `/images/case-study/${videoName}` } poster={ `/images/case-study/${imgName}` } loop autoPlay playsInline controls controlsList="nodownload" disablePictureInPicture disableRemotePlayback />
            <img className="CaseStudy__close" src="/images/back.png" onClick={ handleCloseVideo } />
            <img className="CaseStudy__close CaseStudy__close--hover close-hover" src="/images/back-hover.png" onClick={ handleCloseVideo } />
        </div>
        setVideo(video)
        return
    }
    const handleCloseVideo = () => {
        setVideo()
        return
    }

    useEffect(() => {
        const cases = [...document.querySelectorAll('.CaseSlide__case')]
        cases.map(t => {
            t.addEventListener('mouseenter', e => {
                const video = t.querySelector('video')
                video && video.play()
            })
            t.addEventListener('mouseleave', e => {
                const video = t.querySelector('video')
                video && video.load()
            })
        })
    },[]);

    return (
        <div className="CaseSlide">
            <div className="CaseSlide__case" onClick={ () => handleShowVideo(case1.videoName, case1.imgName) }>
                { case1.videoName && <LazyLoad height={'100%'} offset={200} once><video className='CaseSlide__teaser' src={ `/images/case-study/teasers/${case1.videoName}${case1.imgName ? '' : '#t=0.01'}` } poster={ case1.imgName && `/images/case-study/teasers/posters/${case1.imgName}` } data-responsive="parent" loop muted autoPlay={ deviceType === 'mobile' } playsInline /></LazyLoad> }
                <div className="CaseSlide__header">
                    { case1.company && <p className="CaseSlide__company">{ case1.company }</p> }
                    { case1.product && <p className="CaseSlide__product">{ case1.product }</p> }
                </div>
                <p className="CaseSlide__type">{ case1.type }<span className="CaseSlide__arrow"></span></p>
                <Button txt={ txt[language].button } component='CaseSlide' color='green' />
            </div>
            {   
                case2 &&
                <div className="CaseSlide__case" onClick={ () => handleShowVideo(case2.videoName, case2.imgName) }>
                    { case2.videoName && <LazyLoad height={'100%'} offset={200} once><video className='CaseSlide__teaser' src={ `/images/case-study/teasers/${case2.videoName}${case2.imgName ? '' : '#t=0.01'}` } poster={ case2.imgName && `/images/case-study/teasers/posters/${case2.imgName}` } data-responsive="parent" loop muted autoPlay={ deviceType === 'mobile' } playsInline /></LazyLoad> }
                    <div className="CaseSlide__header">
                        { case2.company && <p className="CaseSlide__company">{ case2.company }</p> }
                        { case2.product && <p className="CaseSlide__product">{ case2.product }</p> }
                    </div>
                    <p className="CaseSlide__type">{ case2.type }<span className="CaseSlide__arrow"></span></p>
                    <Button txt={ txt[language].button } component='CaseSlide' color='green' />
                </div>
            }
        </div>
    )
}

export default CaseSlide