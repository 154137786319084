export function detectDevice() {
    let hasTouchScreen = false
    if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0
    } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0
    } else {
        const mQ = window.matchMedia && matchMedia("(pointer:coarse)")
        if (mQ && mQ.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches
        } else if ("orientation" in window) {
            hasTouchScreen = true // deprecated, but good fallback
        } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent
            hasTouchScreen =
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
        }
    }
    return hasTouchScreen ? 'mobile' : 'desktop'
}

export function detectLanguage(availableLanguages) {
    let userLanguage = localStorage.getItem('language')
    if(!userLanguage) {
        let browserLanguage = window.navigator.language || window.navigator.userLanguage
        if(browserLanguage) {
            userLanguage = browserLanguage.split('-')[0]
        }
    }
    if(!userLanguage || !availableLanguages.includes(userLanguage)) {
        userLanguage = 'en'
    }
    return userLanguage
}

// Set the image / video responsive to width or height, depending od the window ratio
export function responsive(el) {
    function setCSS(element, resize) {
        let parent = {
            w: element.parentElement.clientWidth,
            h: element.parentElement.clientHeight,
        }
        parent.ratio = parent.w/parent.h
        let el = {
            w: element.offsetWidth,
            h: element.offsetHeight,
        }
        el.ratio = el.w/el.h
        
        if(parent.ratio > el.ratio) {
            element.style.width = parent.w+'px'
            element.style.height = 'auto'
            element.style.left = 0
            element.style.top = -(( element.offsetHeight - parent.h )/2)+'px'
        } else {
            element.style.width = 'auto'
            element.style.height = parent.h+'px'
            element.style.left = -(( element.offsetWidth - parent.w )/2)+'px'
            element.style.top = 0
        }
        if(resize == true) {
            window.addEventListener('resize', () => { setCSS(element) })
        }
    }
    function init() {
        if(el) {
            setCSS( el, true )
        } else {
            let elements = [...document.querySelectorAll('*[data-responsive="parent"]')]
            elements.map(el => setCSS( el, true ))
        }
    }
    setTimeout(() => init(),100)
}

export function throttle(func, interval = 400) {
    let lastCall = 0
    return function() {
        let now = Date.now()
        if (lastCall + interval < now) {
            lastCall = now
            return func.apply(this, arguments)
        }
    }
}

export function parallax(container, targets, xMovement = 1, directionX = -1, yMovement = 1, directionY = -1, xLimits = [], yLimits = [],attribute) {
    const maxX = container.offsetWidth;
    const maxY = container.offsetHeight;
    if (maxX <= 1) return;
    if (maxY <= 1) return;
    let posX = 0;
    let posY = 0;
    
    container.addEventListener('mousemove', (e) => {
        posX = (e.x - maxX / 2) / (maxX / 2);
        if (xLimits[0] && posX < xLimits[0]) return;
        if (xLimits[1] && posX > xLimits[1]) return;
        posY = (e.y - maxY / 2) / (maxY / 2);
        if (yLimits[0] && posY < yLimits[0]) return;
        if (yLimits[1] && posY > yLimits[1]) return;
        targets.forEach(el => {
            if(attribute === 'bg') {
                el.style.backgroundPosition = `${directionX * 100 * posX * xMovement + 50}%, 0`
            } else {
                el.style.transform = `translate(
                    ${directionX * 100 * posX * xMovement}%,
                    ${directionY * 100 * posY * yMovement}%
                )`
            }
        })
    });
}

/** 
 * Form 
 */
export const form = {
    validate: {
        email: function(email) {
            return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) ? true : false
        },
        message: function(message) {
            return message.length > 3
        },
        subject: function(subject) {
            return subject.length <= 100 && subject.length > 0
        },
    },
    send: function(url, data, callback) {
        fetch(url, {
            method: 'POST',
            // mode: 'no-cors',
            // headers: {
                // 'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*',
            // },
            body: JSON.stringify(data),
        })
            .then((response) => {
                return response.json()
            })
            .then((result) => {
                if(result.success) {
                    callback()
                }
                console.log('Success:', result);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        return
    },
}
