import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Reveal from "./hoc/Reveal"
import '../styles/Clients.scss';

const txt = {
    pl: {
    },
    en: {
    },
}
const logotypes = [
    ['mediacom.png', 142],
    ['mindshare.png', 143],
    ['wavemaker.png', 188],
    ['starcom.png', 187],
    ['zenith.png', 108],
    ['initiative.png', 125],
    ['reprise.png', 126],
    ['um.png', 57],
    ['mediadirection.png', 107],
    ['omp.png', 82],
    ['phd.png', 79],

    ['mullenlowe.png', 199],
    ['dentsu.png', 105],
    ['havas.png', 111],
    ['precise.png', 129],
    ['mediaplus.png', 254],
    ['mediarepublic.png', 166],
    ['rl.png', 52],
    ['salestube.png', 126],
    ['valuemedia.png', 158],
    ['sales_more.png', 80],

    ['vw.png', 57],
    ['audi.png', 90],
    ['porsche.png', 104],
    ['skoda.png', 138],
    ['seat.png', 63],
    ['bmw.png', 58],
    ['mini.png', 103],
    ['toyota.png', 122],
    ['honda.png', 135],
    ['renault.png', 73],
    ['hyundai.png', 148],
    ['cola.png', 102],
    ['pepsi.png', 109],

    ['lipton.png', 66],
    ['macdonald.png', 52],
    ['kfc.png', 107],
    ['snickers.png', 129],
    ['kruszwica.png', 107],
    ['studentska.png', 104],
    ['monini.png', 90],
    ['jemy.png', 72],
    ['winiary.png', 74],
    ['mercedes.png', 168],
    ['range_rover.png', 191],
    ['peugeot.png', 71],

    ['ford.png', 94],
    ['kn.png', 106],
    ['volvo.png', 57],
    ['dacia.png', 138],
    ['mazda.png', 54],
    ['lexus.png', 117],
    ['nissan.png', 67],
    ['loreal.png', 133],
    ['la_roche.png', 155],
    ['nivea.png', 60],
    ['phlov.png', 115],
    ['tolpa.png', 112],
    ['bourjuis.png', 116],

    ['kontigo.png', 106],
    ['sephora.png', 133],
    ['dove.png', 78],
    ['clinique.png', 124],
    ['esteelauder.png', 180],
    ['calvin_klein.png', 153],
    ['avon.png', 108],
    ['jo_malone.png', 137],
    ['dr_irena_eris.png', 147],
    ['max_factor.png', 184],

    ['vichy.png', 123],
    ['rexona.png', 54],
    ['alpro.png', 84],
    ['carlsberg.png', 86],
    ['zywiec.png', 150],
    ['netflix.png', 102],
    ['hbo.png', 77],
    ['prime_video.png', 143],
    ['disney.png', 118],
    ['tvn.png', 58],
    ['lego.png', 51],
    ['ps.png', 75],
    ['cenega.png', 133],

    ['ubisoft.png', 66],
    ['rimmel.png', 151],
    ['wella.png', 104],
    ['pharmaceris.png', 152],
    ['marseillais.png', 78],
    ['sally_hansen.png', 130],
    ['garnier.png', 162],
    ['kaufland.png', 77],
    ['pepco.png', 124],
    ['rossmann.png', 134],
    ['sokolow.png', 102],

    ['zabka.png', 87],
    ['mosso.png', 87],
    ['heinz.png', 79],
    ['danone.png', 118],
    ['almette.png', 118],
    ['hochland.png', 84],
    ['subway.png', 128],
    ['tchibo.png', 122],
    ['jacobs.png', 124],
    ['ferrero.png', 134],

    // 'one_group.png',
]

const Clients = ({language}) => {
    const [isWindowWidthSmaller922, setIsWindowWidthSmaller922] = useState(false)
    useEffect(() => {
        const windowWidth = window.innerWidth
        if(windowWidth <= 922) {
            setIsWindowWidthSmaller922(true)
        } else {
            setIsWindowWidthSmaller922(false)
        }

    })

    return (
        <section className="Clients">
            <div id="Clients" className="container Clients__container">
                <div className="Clients__logotypes">
                    { logotypes.map((logo, i) => 
                        <div className="" key={ i }>
                            <Reveal trigger='#Clients' start={`${i/logotypes.length * 10}% center`} end={`${i/logotypes.length * 70}% center`}>
                                <img className="Clients__logo" src={ `/images/clients/${logo[0]}` }  style={{width: `${isWindowWidthSmaller922 ? logo[1]/360*100*.345 : logo[1]/1920*100}vw`}}/>
                            </Reveal>
                        </div>) 
                    }
                </div>
            </div>
        </section>
    )
}

Clients.propTypes = {
    language: PropTypes.string.isRequired,
}

export default Clients