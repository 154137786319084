import React from 'react'
import PropTypes from 'prop-types'
import '../styles/Button.scss';

const Button = ({action, component, color, txt}) => {
    return (
        <button className={`Button Button--${color} ${component}__button`} onClick={ action ? action : ()=>{} }>{ txt }</button>
    )
}

Button.propTypes = {
    txt: PropTypes.string.isRequired,
}

export default Button