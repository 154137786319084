import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Reveal from "./hoc/Reveal";
import '../styles/OthersAboutUs.scss'

const txt = {
    pl: {
        references: [
            {
                author: 'Adam Matwijczyk',
                copy: 'Gdyby Leonardo da Vinci żył w&nbsp;obecnych<br>czasach, to z&nbsp;pewnością byłoby<br>mu bardzo blisko do Sataku. Innowacyjne<br>spojrzenie oraz&nbsp;wszechstronna wiedza<br>sprawiają, że&nbsp;Sataku wyznacza trendy<br>kreatywnej współpracy.',
                position: 'Head of Performance',
                company: 'Digital Resolution',
            },
            {
                author: 'Agnieszka Kuczyńska-Pietrzyk',
                copy: 'Sataku kojarzy się z&nbsp;kreatywnością<br>i&nbsp;zaangażowaniem. Dla&nbsp;mnie to&nbsp;także<br>umiejętność połączenia potrzeb<br>marketingowych różnych rynków<br>w&nbsp;jednej kreacji.',
                position: 'Media Manager',
                company: 'PEPCO',
            },
            {
                author: 'Klaudia Dadacz',
                copy: 'Wieloletnia współpraca z&nbsp;Sataku na&nbsp;różnych markach, pokazuje elastyczność, otwartość i&nbsp;zrozumienie innych oczekiwań w&nbsp;zależności od&nbsp;branży -&nbsp;co&nbsp;jest bardzo istotne przy<br>pełnej ograniczeń farmacji.',
                position: 'Brand Manager',
                company: 'Haleon',
            },
            {
                author: 'Matylda Zasada',
                copy: 'Tempo powstawania pomysłów i&nbsp;tworzenia<br>kreacji w&nbsp;połączeniu z&nbsp;dowożonymi<br>wynikami kampanii to&nbsp;główne czynniki,<br>dla&nbsp;których Sataku tak często znajduje<br>się w&nbsp;mediaplanach.',
                position: 'Digital Manager',
                company: 'Danone',
            },
            {
                author: 'Jakub Kozłowski',
                copy: 'Nie sztuką jest robić rich media,<br>sztuką jest robić to&nbsp;tak dobrze.',
                position: 'Head of Digital Product',
                company: 'Reprise',
            },
            {
                author: 'Aleksandra Pająk',
                copy: 'Z&nbsp;Sataku pracuje się wyśmienicie, Client<br>Service na&nbsp;najwyższym poziomie. Nie&nbsp;tylko<br>zawsze z&nbsp;zaangażowaniem podchodzą<br>do powierzonych zadań, ale&nbsp;też sami<br>proponują własne kreatywne rozwiązania.<br>Warto mieć takiego partnera biznesowego!',
                position: 'Communication Director',
                company: 'Value Media',
            },
        ],
    },
    en: {
        references: [
            {
                author: 'Adam Matwijczyk',
                copy: 'If Leonardo Da Vinci lived in our times he would be very close to Sataku. Innovative perspective and&nbsp;vast knowledge are landmarks of Sataku which paves the&nbsp;way for creative cooperation.',
                position: 'Head of Performance',
                company: 'Digital Resolution',
            },
            {
                author: 'Agnieszka Kuczyńska-Pietrzyk',
                copy: 'Sataku means creativity and&nbsp;engagement. For me it also means a&nbsp;unique skill of combining marketing needs of multiple areas into one creation.',
                position: 'Media Manager',
                company: 'PEPCO',
            },
            {
                author: 'Klaudia Dadacz',
                copy: 'Many years of cooperation on various brands shows flexibility, openess and&nbsp;understanding of different branch dependent expectations which is essential with pharmacy full of limitations.',
                position: 'Brand Manager',
                company: 'Haleon',
            },
            {
                author: 'Matylda Zasada',
                copy: 'The speed of idea birth and&nbsp;creation combined with real time campain results update are the&nbsp;main factors why Sataku is&nbsp;present in mediaplans.',
                position: 'Digital Manager',
                company: 'Danone',
            },
            {
                author: 'Jakub Kozłowski',
                copy: 'Rich media making is no deal<br>but our quality is real.',
                position: 'Head of Digital Product',
                company: 'Reprise',
            },
            {
                author: 'Aleksandra Pająk',
                copy: 'Cooperation with Sataku is outstanding, their client service present the&nbsp;highest level. They are not only committed to entrusted tasks but also propose their own creative solutions. It is great to have such&nbsp;business partner!',
                position: 'Communication Director',
                company: 'Value Media',
            },
        ],
    },
}
const awards = [
    ['mmp2022.png',
    'mmp2020.png',
    'mmp2019.png',
    'mmp2018.png',
    'mmp2017.png'],
    ['fast50.png',
    'fast500.png',
    'ranking50.png'],
    ['ft.png',
    'dyrektor.png',
    'kampania.png',
    'protony.png',
    'odpowiedzialni.png'],
    ['iab.png',
    'qualid.png'],
] 

const OthersAboutUs = ({language}) => {
    let [activeReference, setActiveReference] = useState(0)
    let intervalReference
    let references

    useEffect(() => {
        references = [...document.querySelectorAll('.OthersAboutUs__reference')]
        intervalReference = setInterval(() => setActiveReference(activeReference === references.length - 1 ? 0 : activeReference+1),10000)
        return () => clearInterval(intervalReference)
    })

    const changeReference = ({i, dir}) => {
        clearInterval(intervalReference)
        if(i !== undefined) {
            setActiveReference(i)
            return
        }
        if(dir < 0) {
            setActiveReference(activeReference === 0 ? references.length - 1 : activeReference-1)
        }
        if(dir > 0) {
            setActiveReference(activeReference === references.length - 1 ? 0 : activeReference+1)
        }
        return
    }

    return (
        <section className="OthersAboutUs">
            <div id="OthersAboutUs" className="container container--splitted">
                <section className="OthersAboutUs__left">
                    <div className="OthersAboutUs__awards">
                        { awards.map((awardsList, i) => 
                            <div key={ i } className="OthersAboutUs__awards-list">
                                { awardsList.map((award, j) => 
                                        <img key={j} className={`OthersAboutUs__award OthersAboutUs__award_${j}`} src={ `/images/reputation/${award}` } />
                                )}
                            </div>
                        )}
                    </div>
                </section>
                <section className="OthersAboutUs__right">
                    <Reveal trigger='#OthersAboutUs'>
                        <div className="OthersAboutUs__references">
                            { txt[language].references.map((ref, i) =>
                                <div className={ `OthersAboutUs__reference ${i === activeReference ? 'active': ''}` } key={ i }>
                                    <p className="OthersAboutUs__reference-txt" dangerouslySetInnerHTML={{__html: ref.copy}} />
                                    <p className="OthersAboutUs__reference-txt">{ ref.author }</p>
                                    <p className="OthersAboutUs__reference-txt">{ ref.position }</p>
                                    <p className="OthersAboutUs__reference-txt">{ ref.company }</p>
                                </div>
                            )}
                        </div>
                    </Reveal>
                    <div className="OthersAboutUs__controls">
                        <img className="OthersAboutUs__arrow OthersAboutUs__arrow--left" src="/images/arrow-dark-green.png" onClick={ () => changeReference({dir:-1}) } />
                        <img className="OthersAboutUs__arrow OthersAboutUs__arrow--left OthersAboutUs__arrow--hover arrow-hover" src="/images/arrow-dark-green-hover.png" />
                        <img className="OthersAboutUs__arrow OthersAboutUs__arrow--right" src="/images/arrow-dark-green.png" onClick={ () => changeReference({dir:1}) } />
                        <img className="OthersAboutUs__arrow OthersAboutUs__arrow--right OthersAboutUs__arrow--hover arrow-hover" src="/images/arrow-dark-green-hover.png" />
                        <div className="OthersAboutUs__dots">
                            { txt[language].references.map((ref,i) =>
                                <button key={ i } className={ `OthersAboutUs__dot ${i === activeReference ? 'active': ''}` } onClick={ () => changeReference({i: i}) } />
                            ) }
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}

OthersAboutUs.propTypes = {
    language: PropTypes.string.isRequired,
}

export default OthersAboutUs