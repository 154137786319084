import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from './Button';
import '../styles/Showreel.scss';

const txt = {
    pl: {
        button: 'zobacz showreel',
    },
    en: {
        button: 'watch showreel',
    },
}

const Showreel = ({language, setScrolled}) => {
    const [active, setActive] = useState('')
    const handlePlayVIdeo = () => {
        let videoIframe = document.querySelector('.Showreel__iframe')
        setTimeout(() => videoIframe.src += "&autoplay=1", 400)
        setActive('active')
        setScrolled(true)
        return
    }
    const handleStopVIdeo = () => {
        let videoIframe = document.querySelector('.Showreel__iframe')
        videoIframe.src = videoIframe.src.replace("&autoplay=1", "")
        setActive('')
        return
    }

    useEffect(() => {
        // for ios
        const teaser = document.querySelector('.Showreel__teaser')
        teaser.muted = true
        teaser.play()
    },[])
    
    return (
        <section id='Showreel' className='Showreel'>
            <div className='container'>
                {/* <video className='Showreel__teaser' data-responsive="parent" loop muted autoPlay playsInline>
                    <source src="/video/showreel.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                    <source src="/video/showreel.webm" type='video/webm; codecs="vp8, vorbis"' />
                    <img src="/video/showreel-poster.jpg" />
                </video> */}
                <video className='Showreel__teaser' src='/video/showreel.mp4' poster='/video/showreel-poster.jpg' data-responsive="parent" loop muted autoPlay playsInline />
                <Button txt={ txt[language].button } component='Showreel' color='green' action={ handlePlayVIdeo } />
                <div className={ `Showreel__iframe-wrapper ${active}` }>
                    <iframe className='Showreel__iframe' frameBorder='0' allowFullScreen='1' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' title='SATAKU. Video / Rich Media Reel' width='640' height='360' src='https://www.youtube.com/embed/fKJrZD672CQ?showinfo=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fsataku.com&amp;widgetid=1'></iframe>
                    <img className="Showreel__close" src="/images/back.png" onClick={ handleStopVIdeo } />
                    <img className="Showreel__close Showreel__close--hover close-hover" src="/images/back-hover.png" />
                </div>
            </div>
        </section>
    )
}

Showreel.propTypes = {
    language: PropTypes.string.isRequired,
}

export default Showreel