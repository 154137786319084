import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Reveal from './hoc/Reveal'
import '../styles/AboutUs.scss'
import { parallax } from '../utils.js'
import { gsap } from 'gsap'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
gsap.registerPlugin(CSSRulePlugin)

const txt = {
    pl: {
        leftBig: 'Jesteśmy największą<br>siecią reklamy<br>Rich&nbspMedia w&nbsp;Polsce.<br>Realizujemy kampanie<br>na całym świecie.',
        leftSmall: 'W naszych kampaniach najważniejsze jest <br><span class="AboutUs__underline underline">zaangażowanie użytkownika i interakcja.</span>',
        rightGreen: ['CPC', 'Kreacja gratis', 'Ponad 4 000', '11'],
        rightWhite: ['Model rozliczeń', '', 'Liczba zrealizowanych kampanii', 'Lat na rynku'],
    },
    en: {
        leftBig: 'We are the biggest Rich Media Network in&nbsp;Poland. We&nbsp;realize campaigns around the&nbsp;World. ',
        leftSmall: 'Our campaigns set interaction and <br><span class="AboutUs__underline underline">user involvement as priority.</span>',
        rightGreen: ['CPC', 'Free creations', 'Over 4000', '11'],
        rightWhite: ['Settlement model', '', 'Successful campaigns', 'Years of market experience'],
    },
}

const AboutUs = ({language}) => {
    const rightRows = []
    for(let i = 0; i < txt[language].rightGreen.length; i++) {
        rightRows.push(
            <div key={ i }>
                { txt[language].rightGreen[i] && <div className="AboutUs__text-green">{ txt[language].rightGreen[i] }</div> }
                { txt[language].rightWhite[i] && <div className="AboutUs__text-white">{ txt[language].rightWhite[i] }</div> }
            </div>
        )
    }
    
    useEffect(() => {
        const imageWrapper = document.querySelector('.AboutUs__side--right')
        const image = document.querySelector('.AboutUs__bg')
        parallax(imageWrapper, [image], .03, 1, 0, 0, [], [], 'bg')

        gsap.fromTo(CSSRulePlugin.getRule(".underline::before"), 
            {cssRule: {
                clipPath: 'polygon(0 0, 0 0, 0 100%, 0 100%)',
                opacity: 0
            }},
            {cssRule: {
                    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
                    opacity: 1
            },
            scrollTrigger: {
                trigger: '#AboutUs',
                start: '35% center',
                end: '45% center',
                scrub: 1,
                // markers: true,
            }}
        )
        return
    }, [])

    return (
        <section className="AboutUs">
            <div id="AboutUs" className='container container--splitted'>
                <section className="AboutUs__side AboutUs__side--left">
                    <Reveal trigger='#AboutUs'>
                        <div className='AboutUs__box'>
                            <p className="AboutUs__text-big" dangerouslySetInnerHTML={{__html: txt[language].leftBig}} />
                            <p className="AboutUs__text-small" dangerouslySetInnerHTML={{__html: txt[language].leftSmall}} />
                        </div>
                    </Reveal>
                </section>
                <section className="AboutUs__side AboutUs__side--right">
                    <div className="AboutUs__bg"></div>
                    {/* <img className="AboutUs__bg" src="/images/about-us-bg.jpg" /> */}
                    <Reveal trigger='#AboutUs'>
                        <div className='AboutUs__box'>
                            <div className='AboutUs__right-rows'>
                                { rightRows }
                            </div>
                        </div>
                    </Reveal>
                </section>
            </div>
        </section>
    )
}

AboutUs.propTypes = {
    language: PropTypes.string.isRequired,
}

export default AboutUs