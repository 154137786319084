import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { form } from "../utils";
import "../styles/ContactForm.scss";

const txt = {
  pl: {
    button: "wyślij",
    clausePart1: `Twoje dane będą przetwarzane przez Sarigato sp. z o.o., ul. Biała droga 13, 30-­327 Kraków, KRS 0000399231, NIP 6762449931, REGON 122423766. Przetwarzamy Twoje dane <strong>wyłącznie celem udzielenia odpowiedzi na wiadomość</strong> skierowaną do nas przez formularz kontaktowy. Jeśli chcesz dowiedzieć się więcej, co Sarigato robi z Twoimi danymi zapoznaj się z <a href="https://sarigato.com/polityka_prywatnosci_sarigato_sataku.pdf">Polityką Prywatności</a>.`,
    clausePart2: "",
    clauseMobilePart1: `Twoje dane będą przetwarzane przez Sarigato sp. z o.o., ul. Biała droga 13, 30-­327 Kraków, KRS 0000399231, NIP 6762449931, REGON 122423766. Przetwarzamy Twoje dane <strong>wyłącznie celem udzielenia odpowiedzi na wiadomość</strong> skierowaną do nas przez formularz kontaktowy. Jeśli chcesz dowiedzieć się więcej, co Sarigato robi z Twoimi danymi zapoznaj się z <a href="https://sarigato.com/polityka_prywatnosci_sarigato_sataku.pdf">Polityką Prywatności</a>.`,
    clauseMobilePart2: "",
    clauseMore: "Rozwiń",
    clauseLess: "Zwiń",
    header: "Skontaktuj się \nz nami",
    formSendedInfo: "Twoja wiadomość została wysłana",
    formErrors: {
      email: "*Niepoprawny adres email",
      message: "*Brak treści wiadomości",
    },
    formInputs: {
      email: "Email",
      subject: "Temat",
      message: "Twoja wiadomość",
    },
  },
  en: {
    button: "send",
    clausePart1: `Twoje dane będą przetwarzane przez Sarigato sp. z o.o., ul. Biała droga 13, 30-­327 Kraków, KRS 0000399231, NIP 6762449931, REGON 122423766. Przetwarzamy Twoje dane <strong>wyłącznie celem udzielenia odpowiedzi na wiadomość</strong> skierowaną do nas przez formularz kontaktowy. Jeśli chcesz dowiedzieć się więcej, co Sarigato robi z Twoimi danymi zapoznaj się z <a href="https://sarigato.com/polityka_prywatnosci_sarigato_sataku.pdf">Polityką Prywatności</a>.`,
    clausePart2: "",
    clauseMobilePart1: `Twoje dane będą przetwarzane przez Sarigato sp. z o.o., ul. Biała droga 13, 30-­327 Kraków, KRS 0000399231, NIP 6762449931, REGON 122423766. Przetwarzamy Twoje dane <strong>wyłącznie celem udzielenia odpowiedzi na wiadomość</strong> skierowaną do nas przez formularz kontaktowy. Jeśli chcesz dowiedzieć się więcej, co Sarigato robi z Twoimi danymi zapoznaj się z <a href="https://sarigato.com/polityka_prywatnosci_sarigato_sataku.pdf">Polityką Prywatności</a>.`,
    clauseMobilePart2: "",
    clauseMore: "More",
    clauseLess: "Less",
    header: "Contact with us",
    formSendedInfo: "Message sent successfully",
    formErrors: {
      email: "*Incorrect email address",
      message: "*No message",
    },
    formInputs: {
      email: "Email",
      subject: "Subject",
      message: "Your message",
    },
  },
};

const ContactForm = ({ language, deviceType }) => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isClauseFullTekst, setIsClauseFullTekst] = useState(false);

  const handleInputChange = (e) => {
    e.target.name === "email" && setEmail(e.target.value);
    e.target.name === "message" && setMessage(e.target.value);
    e.target.name === "subject" && setSubject(e.target.value);

    form.validate[e.target.name](e.target.value)
      ? e.target.classList.remove("invalid")
      : e.target.classList.add("invalid");
  };
  const handleForm = (e) => {
    e.preventDefault();

    // Check if form filled by the bot
    const isBot = document.querySelector("#form-antibot").value ? true : false;
    if (isBot) return;

    const data = {
      email: email,
      subject: subject,
      message: message,
    };

    // Validation
    const validEmail = form.validate.email(data.email);
    const validSubject = form.validate.subject(data.subject);
    const validMessage = form.validate.message(data.message);
    const userDataValid = validEmail && validSubject && validMessage;
    if (!userDataValid) {
      console.log("Form data not valid!");
      return;
    }

    // Send form
    const url = "https://contact-api.sataku.com/api/send";
    form.send(url, data, showResponse);

    if (deviceType === "mobile") {
      highlightTouchedButton(e.target);
    }
  };
  const showResponse = () => {
    const info = document.querySelector(".ContactForm__form-sended");
    info.classList.add("show");
    // setTimeout(() => info.classList.remove('show'),3000)
  };
  const highlightTouchedButton = (button) => {
    button.classList.add("highlight");
    setTimeout(() => button.classList.remove("highlight"), 200);
  };

  return (
    <section
      className={`ContactForm ${
        isClauseFullTekst ? "ContactForm__clause-full" : ""
      }`}
    >
      <div id="ContactForm" className="ContactForm__container container">
        <div className="ContactForm__background">
          <div className="ContactForm__side ContactForm__side--left">
            <header className="ContactForm__header">{`${txt[language].header}`}</header>

            <div className="ContactForm__address">
              <p>
                ul.&nbsp;Biała&nbsp;Droga&nbsp;13, <br />
                <span>30-327</span>&nbsp;Kraków
              </p>
              <p>biuro@sataku.com</p>
              <p>
                NIP:&nbsp;6762449931
                <br />
                <span className="ContactForm__dot"> • </span>
                Regon:&nbsp;122423766
              </p>
            </div>
          </div>
          <div className="ContactForm__side ContactForm__side--right">
            <form className="ContactForm__form">
              <input id="form-antibot" className="input-antibot" />
              <div className="ContactForm__input-box input-box">
                <input
                  id="form-email"
                  type="email"
                  name="email"
                  placeholder={`${txt[language].formInputs.email}`}
                  minLength="8"
                  maxLength="60"
                  required
                  onChange={(e) => handleInputChange(e)}
                  value={email}
                />
                <span className="input-box__error">{`${txt[language].formErrors.email}`}</span>
              </div>
              <div className="ContactForm__input-box input-box">
                <input
                  id="form-subject"
                  type="text"
                  name="subject"
                  placeholder={`${txt[language].formInputs.subject}`}
                  minLength="1"
                  maxLength="100"
                  onChange={(e) => handleInputChange(e)}
                  value={subject}
                />
              </div>
              <div className="ContactForm__input-box ContactForm__input-box--message input-box">
                <textarea
                  id="form-message"
                  name="message"
                  placeholder={`${txt[language].formInputs.message}`}
                  minLength="3"
                  required
                  onChange={(e) => handleInputChange(e)}
                  value={message}
                />
                <span className="input-box__error">{`${txt[language].formErrors.message}`}</span>
              </div>
              <div className="ContactForm__button-box">
                <span className="ContactForm__form-sended">{`${txt[language].formSendedInfo}`}</span>
                <Button
                  txt={txt[language].button}
                  component="ContactForm"
                  color="green"
                  action={handleForm}
                />
              </div>
              <p className="ContactForm__clause">
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      deviceType === "desktop"
                        ? txt[language].clausePart1
                        : txt[language].clauseMobilePart1,
                  }}
                />
                {/* <span>{!isClauseFullTekst && ".. "}</span>
                <span
                  className="ContactForm__clause-button"
                  onClick={() => setIsClauseFullTekst(true)}
                >
                  {!isClauseFullTekst && `${txt[language].clauseMore}`}
                </span>
                <span>
                  {isClauseFullTekst &&
                    `${
                      deviceType === "desktop"
                        ? txt[language].clausePart2
                        : txt[language].clauseMobilePart2
                    } `}
                </span>
                <span
                  className="ContactForm__clause-button"
                  onClick={() => setIsClauseFullTekst(false)}
                >
                  {isClauseFullTekst && `${txt[language].clauseLess}`}
                </span> */}
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

ContactForm.propTypes = {
  language: PropTypes.string.isRequired,
};

export default ContactForm;
