import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import '../styles/NiceDay.scss';

const txt = {
    pl: {
        goodbye: 'Miłego dnia!',
    },
    en: {
        goodbye: 'Have a nice day!',
    },
}

const NiceDay = ({language}) => {
    return (
        <section className="NiceDay">
            {/* <div id="NiceDay" className="NiceDay__container container"> */}
                <div className="NiceDay__image-wrapper">
                    <LazyLoad height={'100%'} offset={200} once><img className="NiceDay__image" src="/images/people.jpg" /></LazyLoad>
                </div>
                {/* <p className="NiceDay__goodbay">{ txt[language].goodbye }</p> */}
            {/* </div> */}
        </section>
    )
}

NiceDay.propTypes = {
    language: PropTypes.string.isRequired,
}

export default NiceDay