import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import Reveal from "./hoc/Reveal"
import { Tween } from 'react-gsap'
import '../styles/Contact.scss';

const txt = {
    pl: {
        header: 'Kontakt',
        personRightHeader: 'WYDAWCY',
        personLeftHeader: 'WSPÓŁPRACA',
    },
    en: {
        header: 'Contact',
        personRightHeader: 'PUBLISHERS',
        personLeftHeader: 'COOPERATION',
    },
}
const personalData = {
    personLeft: {
        firstName: 'Ewa',
        lastName: 'Bogiel',
        tel: '+48 609 851 230',
        email: 'ewa.bogiel@sataku.com'
    },
    personRight: {
        firstName: 'Karolina',
        lastName: 'Sokołowska',
        tel: '+48 506 292 121',
        email: 'karolina.sokolowska@sataku.com'
    },
}

const Contact = ({language, deviceType}) => {
    return (
        <section className="Contact">
            <div id="Contact" className="Contact__container container">
                <header className='Contact__header'>{ `${txt[language].header}` }</header>
                <Tween from={{ x: `${deviceType === 'desktop' ? '-22%' : ''}` }} to={{
                    x: 0,
                    scrollTrigger: {
                        trigger: '#Contact',
                        start: '-100px center',
                        end: '400px center',
                        scrub: 0.5,
                        // markers: true,
                    },
                }} >
                    <div className="Contact__persons-container">
                        <div className="Contact__person-wrapper">
                            <div className="Contact__person Contact__person--left">
                                <LazyLoad height={'100%'} offset={200}><img className="Contact__person-image" src="/images/contact/ewa.jpg" /></LazyLoad>
                                <Reveal trigger='#Contact' start='20% center'>
                                    <div className="Contact__person-info Contact__person-info-left">
                                        <p className="Contact__person-info-header">{ txt[language].personLeftHeader }</p>
                                        <p className="Contact__person-info-name">{ personalData.personLeft.firstName } { personalData.personLeft.lastName }</p>
                                        <p className="Contact__person-info-tel"><a href={ `tel:${ personalData.personLeft.tel }}` }>{ personalData.personLeft.tel }</a></p>
                                        <p className="Contact__person-info-email"><a href={ `mailto:${ personalData.personLeft.email }}` }>{ personalData.personLeft.email }</a></p>
                                    </div>
                                </Reveal>
                            </div>
                        </div>
                        <div className="Contact__person-wrapper">
                            <Tween from={{ y: `${deviceType === 'mobile' ? '-64%' : ''}` }} to={{
                                y: 0,
                                scrollTrigger: {
                                    trigger: '#Contact',
                                    start: '-100px center',
                                    end: '400px center',
                                    scrub: 0.5,
                                    // markers: true,
                                },
                            }} >
                                <div className="Contact__person Contact__person--right">
                                    <LazyLoad height={'100%'} offset={200}><img className="Contact__person-image" src="/images/contact/karolina.jpg" /></LazyLoad>
                                    <Reveal trigger='#Contact' start='20% center'>
                                        <div className="Contact__person-info Contact__person-info-right">
                                            <p className="Contact__person-info-header">{ txt[language].personRightHeader }</p>
                                            <p className="Contact__person-info-name">{ personalData.personRight.firstName } { personalData.personRight.lastName }</p>
                                            <p className="Contact__person-info-tel"><a href={ `tel:${ personalData.personRight.tel }}` }>{ personalData.personRight.tel }</a></p>
                                            <p className="Contact__person-info-email"><a href={ `mailto:${ personalData.personRight.email }}` }>{ personalData.personRight.email }</a></p>
                                        </div>
                                    </Reveal>
                                </div>
                            </Tween>
                        </div>
                    </div>
                </Tween>
            </div>
        </section>
    )
}

Contact.propTypes = {
    language: PropTypes.string.isRequired,
}

export default Contact