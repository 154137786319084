import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'
import Reveal from "./hoc/Reveal"
import '../styles/Foundation.scss';

const txt = {
    pl: {
        header: 'Fundacja Sarigato',
        paragraph1: 'Jesteśmy zaangażowani, również społecznie. <br>To dla nas bardzo ważne.',
        paragraph2: 'Dlatego od 11&nbsp;lat prowadzimy fundację, <br>a&nbsp;w&nbsp;ramach niej dwie ogólnopolskie inicjatywy:',
        list: ['Wspieramy bezdomne Psiaki i&nbsp;Kociaki<br>w&nbsp;całej Polsce.', 'Walczymy z&nbsp;wykluczeniem cyfrowym<br>wśród dzieci w&nbsp;Polsce.'],
        button1: 'karmimy psiaki',
        button2: 'hakersi',
    },
    en: {
        header: 'Sarigato Foundation',
        paragraph1: 'We are engaged, also engaged socially. <br>It is very important for us.',
        paragraph2: 'That is why for 11 years we have been running a foundation with two countrywide initiatives:',
        list: ['We support homeless cats and dogs<br>in Poland.', 'We fight against digital exclusion<br>of children in Poland.'],
        button1: 'karmimy psiaki',
        button2: 'hakersi',
    },
}

const Foundation = ({language, deviceType}) => {
    useEffect(() => {
        gsap.fromTo(['.Foundation__img1', '.Foundation__img2'], 
            { x: '-210%' },
            { x: 0,
            scrollTrigger: {
                trigger: '#Foundation',
                start: '15% center',
                end: '30% center',
                scrub: .5,
                // markers: true,
            }}
        )
        gsap.fromTo('.Foundation__img3', 
            { y: '-106%' },
            { y: 0,
            scrollTrigger: {
                trigger: '#Foundation',
                start: '-15% center',
                end: '20% center',
                scrub: .5,
                // markers: true,
            }}
        )
        return
    }, [])

    return (
        <section className="Foundation">
            <div id="Foundation" className="container container--splitted">
                <section className="Foundation__left">
                    <Reveal trigger='#Foundation' start={ deviceType === 'desktop' ? '30% center' : ''}>
                        <div>
                            <header className="Foundation__header">{ txt[language].header }</header>
                            <p className="Foundation__paragraph Foundation__paragraph1" dangerouslySetInnerHTML={{__html: txt[language].paragraph1}} />
                            <p className="Foundation__paragraph Foundation__paragraph2" dangerouslySetInnerHTML={{__html: txt[language].paragraph2}} />
                            <ul className="Foundation__paragraph Foundation__paragraph3">{ txt[language].list.map((el, i) => <li className="Foundation__list-item" key={i} dangerouslySetInnerHTML={{__html: el}} />) }</ul>
                            <a className="Button Button--green Foundation__button" href='https://www.karmimypsiaki.pl' target='_blank'><span>{ txt[language].button1 }</span></a>
                            <a className="Button Button--green Foundation__button" href='https://hakersi.pl' target='_blank'><span>{ txt[language].button2 }</span></a>
                        </div>
                    </Reveal>
                </section>
                <section className="Foundation__right">
                    <div className="Foundation__img Foundation__img1"></div>
                    <div className="Foundation__img Foundation__img2"></div>
                    <div className="Foundation__img Foundation__img3"></div>
                </section>
            </div>
        </section>
    )
}

Foundation.propTypes = {
    language: PropTypes.string.isRequired,
}

export default Foundation